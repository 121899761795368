import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath+'gc/';
const goodsApi = {
    search: (params) => {
        return ajaxHandle(baseurl, 'post', 'public/search', params)
    },
    getType:()=>{
        return ajaxHandle(baseurl, 'post', 'public/goodsType', {}) 
    },
    getPicture:(params)=>{
        return ajaxHandle(baseurl, 'post', 'public/goodsPictureForId', params) 
    },
    delPicture:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/shop/pricture/del', params) 
    },
    add: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/add', params)
    },
    update: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/update', params)
    },
    get: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/get', params)
    },
    del: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/del', params)
    },
    searchMy: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/search', params)
    },
    submit: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/sumit', params)
    },
    finish: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/finish', params)
    },
    getTotal: () => {
        return ajaxHandle(baseurl, 'post', 'api/shop/myGoodsTotal', {})
    },
}

export default goodsApi