import cookies from 'js-cookie';
import cmsApi from '@/api/cmsApi'

const cms={
    state:()=>({
        ads:[],
        categorys:[],
        memberId:cookies.get("member-info") ? JSON.parse(cookies.get("member-info")).id : '',
        messageCount:0,
        finish:{
            ad:0,
            message:0,
            categorys:0
        }
    }),
    getters:{
        getAd:(state,code)=>{
            var backList=[];
            if(state.ads.length>0){
               for(var el in state.ads){
                    if(el.code==code){
                        backList.push(el);
                    }
                }
            }
            return backList;
        },
    },
    mutations:{
        setAds(state){
            cmsApi.adAll().then(res=>{
                if(res.status){
                    state.ads=res.result;
                    state.finish.ad=1;
                }
            });
        },
        setMessageCount(state){
            cmsApi.messageCount().then(res=>{
                if(res.status==200){
                    state.messageCount=res.result;
                    state.finish.message=1;
                }
            });
         },
        setCategorys(state){
            cmsApi.indexAll().then(res=>{
                if(res.status){
                    state.categorys=res.result;
                    state.finish.categorys=1;
                }
            });
        }   
    },
    actions:{
        CmsInit({ commit, state }){
            commit('setAds');
            commit('setCategorys');
            if(state.memberId!=''){
                commit('setMessageCount');
            }
        }
    }
}
export default cms