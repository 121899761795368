import config from '@/config/configUtils.js'
import { useStore } from 'vuex'
const NoticeClient={
    isConnect:false,
    store:null,
    socket:null,
    isClose:false,
    setStore:()=>{
        NoticeClient.store=useStore()
    },
    create:(memberId)=>{
        
        if(typeof(WebSocket) == "undefined") {
            console.log("您的浏览器不支持WebSocket");
        }else{
            console.log("您的浏览器支持WebSocket");
            //实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
            //等同于socket = new WebSocket("ws://localhost:8888/xxxx/im/25");
            //var socketUrl=config.serverUrl;
            //socketUrl=socketUrl.replace("https","ws").replace("http","ws");
            console.log(config.notice_socket.serverUrl);
            if(NoticeClient.socket!=null){
                NoticeClient.socket.close();
                NoticeClient.socket=null;
            }
            try {
                NoticeClient.socket = new WebSocket(config.notice_socket.serverUrl+"/"+memberId);
                NoticeClient.store.commit('notice_setSocket',NoticeClient.socket);
                NoticeClient.socket.onopen = function() {
                    console.log("websocket已打开");
                    //socket.send("这是来自客户端的消息" + location.href + new Date());
                    NoticeClient.isConnect=true;
                    NoticeClient.isClose=false;
                    NoticeClient.store.commit('notice_onOpen',"websocket已打开")
                };
                //获得消息事件
                NoticeClient.socket.onmessage = function(msg) {
                    console.log(msg.data);
                    //发现消息进入    开始处理前端触发逻辑
                    NoticeClient.store.commit('notice_onMessage',msg.data);
                };
                //关闭事件
                NoticeClient.socket.onclose = function() {
                    console.log("websocket已关闭");
                    NoticeClient.isConnect=false;
                    NoticeClient.store.commit('notice_onClose',"websocket已关闭");
                };
                //发生了错误事件
                NoticeClient.socket.onerror = function() {
                    console.log("websocket发生了错误");
                    NoticeClient.isConnect=false;
                    NoticeClient.store.commit('notice_onError',"websocket发生了错误");
                }
             }
             catch(err){
                 console.log(err);
                 NoticeClient.store.commit('notice_onError',"websocket发生了错误");
             }
            
        }
    },
    close:()=>{
        if(NoticeClient.socket!=null){
            NoticeClient.socket.close();
            NoticeClient.socket=null;
            NoticeClient.isClose=true;
        }
    },
    reconnect:()=>{
        if(!NoticeClient.isClose){
            console.log('reconnect');
            NoticeClient.store.commit('notice_reconnect');
        }
       
    }

}

export default NoticeClient