import cookies from 'js-cookie';
import goodsApi from '@/api/goodsApi'
import snapApi from '@/api/snapApi'
import orderApi from "@/api/orderApi";

const common={
    state:()=>({
        loginSrc:'pc',
        token:cookies.get("member-token")?cookies.get("member-token"):'',
        member:'',
        delayeds:[
            {text:'不设置',value:0},
            {text:'5分钟/次',value:5},
            {text:'10分钟/次',value:10},
        ],
        pictureTypes:[
            {text:'列表',value:'list'},
            {text:'轮播',value:'rotation'},
            {text:'默认',value:'default'},
        ],
        visibilitychange:false,
        mySnap:{
            quantity:1,
            forbiddenDay:0,
            isVip:0,
            starTime:''
        },
        goodTypes:[{name:'全部',label:'全部',children:[]}],
        searchParams:{
            draw: 1,
            start: 0,
            length: 10,
            typeId:''
        },
        snapDown: {
            currentTime: new Date(),
            startTime: new Date(),
            endTime: new Date(),
        },
        snapSet:{},
        noticeMessage:{
            isNoticeShow:false,
            title:'',
            type:'',
            doId:'',
            close:false,
            message:''
        },
        orderCount:{
            submit:0,
            distribution:0,
            delivery:0,
            receiving:0,
            receivingsuccess:0,
            nopay:0,
            total:0
          },
        merchantOrderCount:{
            submit:0,
            distribution:0,
            delivery:0,
            receiving:0,
            receivingsuccess:0,
        },
        refundCount:{
            submit:0,
            handing:0,
            over:0,
            all:0
          },
        merchantRefundCount:{
            submit:0,
            handing:0,
            handSystem:0,
            over:0,
            all:0,
        },
        finish:{
            goodTypes:0,
            snap:0  
        },
        isSnapRefresh:false
    }),
    getters:{
        getLoginSrc:(state)=>{
            return cookies.get("loginSrc")?cookies.get("loginSrc"):state.loginSrc;
        },
        getToken:(state)=>{
            return cookies.get("member-token")?cookies.get("member-token"):state.token;
        },
        getMember:(state)=>{
            return cookies.get("member-info")?cookies.get("member-info"):state.member;
        }
    },
    mutations:{
         setGoodsType(state){
            goodsApi.getType().then(res=>{
                if(res.status==200){
                    state.goodTypes= res.result;
                }
                state.finish.goodTypes=1;
            });
         },
         setSnapRefresh(state,value){
            state.isSnapRefresh=value;
         },
         setNoticeMessage(state,notice){
            console.log(notice);
            state.noticeMessage=notice;
            this.commit('setMessageCount');
         },
         setVisibilityChange(state,val){
            state.visibilitychange=val;
         },
         setMySnap(state){
            snapApi.getMySnap().then(res=>{
                if(res.status==200){
                    state.mySnap=res.result;
                    var nowTime=new Date();
                    if(state.mySnap.starTime!=null&&state.mySnap.starTime!=''){
                        state.snapDown.startTime=new Date(state.mySnap.starTime).getTime();
                        state.snapDown.currentTime =  nowTime.getTime();
                        if (
                            state.snapDown.startTime < state.snapDown.currentTime &&
                            state.snapDown.endTime > state.snapDown.currentTime
                        ) {
                            state.snapDown.startTime = state.snapDown.currentTime;
                        }
                    } 
                    console.log(JSON.stringify(state.snapDown));
                }
            });
         },
         setSnap(state){
            snapApi.getSet().then(res=>{
                if(res.status==200){
                    state.snapSet=res.result;
                    state.snapDown.startTime=new Date(state.snapSet.startTime).getTime();
                    state.snapDown.endTime=new Date(state.snapSet.endTime).getTime();
                    var nowTime=new Date();
                    state.snapDown.currentTime =  nowTime.getTime();
                    if (
                        state.snapDown.startTime < state.snapDown.currentTime &&
                        state.snapDown.endTime > state.snapDown.currentTime
                    ) {
                        state.snapDown.startTime = state.snapDown.currentTime;
                    }
                    console.log(
                        "cTime:" +
                        state.snapDown.currentTime +
                        " sTime:" +
                        state.snapDown.startTime +
                        " eTime:" +
                        state.snapDown.endTime
                    );
                    if(state.token!=''){
                        this.commit('setMySnap');
                    }
                }
                state.finish.snap=1;
            });
         },
         setOrderCount(state){
                orderApi.getMyCount().then(res=>{
                  if(res.status==200){
                    state.orderCount=res.result;
                  }
                });
                orderApi.myMerchantCount().then(res=>{
                    if(res.status==200){
                      state.merchantOrderCount=res.result;
                    }
                  });
         },
         setRefund(state){
            orderApi.getMyRefundCount().then(res=>{
                if(res.status==200){
                  state.refundCount=res.result;
                }
              });
              orderApi.myMerchantRefundCount().then(res=>{
                  if(res.status==200){
                    state.merchantRefundCount=res.result;
                  }
                });
         }, 
         setSearchParams(state,params){
             state.searchParams=params;
             console.log(params);
         }
    },
    actions:{
        CommonInit(context){
            context.commit('setGoodsType');
            context.commit('setSnap');
        }
    }
}
export default common