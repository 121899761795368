import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [

  {
    path: '/index',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.index" */ '../views/home/Index.vue'),
    alias:['/index','/'],
  },
  {
    path: '/order',
    name: 'Order',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.order" */ '../views/home/Order.vue')
  },
  {
    path: '/merchantOrder',
    name: 'MerchantOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.merchant" */ '../views/home/MerchantOrder.vue')
  },
  {
    path: '/merchantOrderInfo/:id',
    name: 'OrderInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.merchant" */ '../views/home/merchant/OrderInfo.vue')
  },
  {
    path: '/refundBarter',
    name: 'RefundBarter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.merchant" */ '../views/home/merchant/RefundBarter.vue')
  },
  {
    path: '/memberRBList',
    name: 'memberRBList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.merchant" */ '../views/home/order/memberRBList.vue')
  },
  {
    path: '/merchantRB/:Id',
    name: 'merchantRB',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.merchant" */ '../views/home/merchant/merchantRB.vue')
  },
  {
    path: '/infoDeliver/:Id',
    name: 'InfoDeliver',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.deliver" */ '../views/home/deliver/InfoDeliver.vue')
  },
  {
    path: '/memberRB/:orderId/:Id',
    name: 'memberRB',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.order" */ '../views/home/order/memberRB.vue')
  },
  {
    path: '/orderDetail/:id',
    name: 'OrderDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.order" */ '../views/home/order/OrderDetail.vue')
  },
  {
    path: '/addressList',
    name: 'AddressList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.address" */ '../views/home/address/AddressList.vue')
  },
  {
    path: '/recharge',
    name: 'Recharge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.pay" */ '../views/home/order/Recharge.vue')
  },
  {
    path: '/paysuccess/:id',
    name: 'PaySuccess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.pay" */ '../views/home/order/PaySuccess.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.shopcart" */ '../views/home/ShopCart.vue')
  },
  {
    path: '/auctionDetail/:id',
    name: 'AuctionDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auction.detail" */ '../views/home/auction/AuctionDetail.vue')
  },
  {
    path: '/auctionGoodsType',
    name: 'AuctionGoodsType',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auction.detail" */ '../views/home/auction/AuctionGoodsType.vue')
  },
  {
    path: '/auctionList',
    name: 'AuctionList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auction.detail" */ '../views/home/auction/AuctionList.vue')
  },
  {
    path: '/pay/:type/:id',
    name: 'Pay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.member" */ '../views/home/Pay.vue')
  },
  {
    path: '/member',
    name: 'Member',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.member" */ '../views/home/Member.vue')
  },
  {
    path: '/memberInfo',
    name: 'MemberInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.memberinfo" */ '../views/home/member/MemberInfo.vue')
  },
  {
    path: '/myAccount',
    name: 'MyAccount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.myaccount" */ '../views/home/member/MyAccount.vue')
  },
  {
    path: '/securitySetting',
    name: 'SecuritySetting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.setting" */ '../views/home/member/SecuritySetting.vue')
  },
  {
    path: '/settingPassword',
    name: 'SettingPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.setting" */ '../views/home/member/SettingPassword.vue')
  },
  {
    path: '/goodsList',
    name: 'GoodsList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/goods/GoodsList.vue')
  },
  {
    path: '/goodsEdit/:id',
    name: 'GoodsEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/goods/GoodsEdit.vue')
  },
  {
    path: '/goodsSuccess/:id',
    name: 'GoodsSuccess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/goods/GoodsSuccess.vue')
  },
  {
    path: '/myAuction',
    name: 'MyAuction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/member/MyAuction.vue')
  },
  {
    path: '/myPoints',
    name: 'MyPoints',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/member/MyPoints.vue')
  },
  {
    path: '/myShop',
    name: 'MyShop',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/member/MyShop.vue')
  },
  {
    path: '/snap',
    name: 'Snap',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.snap" */ '../views/home/snap/Snap.vue')
  },
  {
    path: '/snapPayList',
    name: 'SnapPayList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.snap" */ '../views/home/snap/SnapPayList.vue')
  },
  {
    path: '/snapInfo/:id',
    name: 'SnapInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.snap" */ '../views/home/snap/SnapInfo.vue')
  },
  {
    path: '/message',
    name: 'Message',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.message" */ '../views/home/message/Message.vue')
  },
  {
    path: '/notice/:id',
    name: 'Notice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/message/Notice.vue')
  },
  {
    path: '/pointsGoods/:id',
    name: 'PointsGoodsInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/points/PointsGoodsInfo.vue')
  },
  {
    path: '/shop/:id',
    name: 'Shop',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.shop" */ '../views/home/shop/Shop.vue')
  },
  {
    path: '/pointsGoodsList',
    name: 'PointsGoodsList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/points/PointsGoodsList.vue')
  },
  {
    path: '/auctionPush',
    name: 'AuctionPush',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.goods" */ '../views/home/member/AuctionPush.vue')
  },
  {
    path: '/auctionLive',
    name: 'AuctionLive',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.auction" */ '../views/home/auction/AuctionLive.vue')
  },
  {
    path: '/myPoints',
    name: 'MyPoints',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.member" */ '../views/home/member/MyPoints.vue')
  },
  {
    path: '/myBond',
    name: 'MyBond',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.member" */ '../views/home/member/MyBond.vue')
  },
  {
    path: '/myRedPacket',
    name: 'MyRedPacket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home.member" */ '../views/home/member/MyRedPacket.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
