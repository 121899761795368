import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath+'snap';
const shopApi = {
    search: (params) => {
        return ajaxHandle(baseurl, 'post', 'search', params)
    },
    getSet:()=>{
        return ajaxHandle(baseurl, 'post', 'getSet', {}) 
    },
    get:(params)=>{
        return ajaxHandle(baseurl, 'post', 'get', params) 
    },
    bid:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/bid', params) 
    },
    getPay:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/pay/get', params) 
    },
    getTop5:()=>{
        return ajaxHandle(baseurl, 'post', 'getTop5', {}) 
    },
    getMySnap:()=>{
        return ajaxHandle(baseurl, 'post', 'api/getMySnap', {}) 
    },
    getMySnapPay:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/getMySnapPay', params) 
    },
    getPayCount:()=>{
        return ajaxHandle(baseurl, 'post', 'api/getPayCount', {}) 
    },
    pickUp:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/pickUp', params);
    },
}

export default shopApi