import client from '@/utils/NoticeClient.js'
import config from '@/config/configUtils.js'
import stringUtils from '@/utils/stringUtils.js'
import cookies from 'js-cookie';
import { Notify } from 'vant';

const notice = {
    state:{
        socket: null,
        printInfo: '',
        serverMessage: '',
        isConnect: false,
        memberId:cookies.get("member-info") ? JSON.parse(cookies.get("member-info")).member.id : '',
        heartNoticeInterval: null,
    },
    mutations: {
        notice_onOpen(state, message) {
            state.serverMessage = message;
            state.isConnect = true;
            if (state.heartNoticeInterval != null) {
                clearInterval(state.heartNoticeInterval);
            }
            state.heartNoticeInterval = setInterval(() => {
                var sendParams = { type: 'ping', message: '11111', memberId: state.memberId }
                state.socket.send(JSON.stringify(sendParams));
            }, config.notice_socket.monitorTime)
        },
        notice_onMessage(state, message) {
            var ms = { type: 'ping', message: '', result: '' };
            if (message && message != '') {
                ms = JSON.parse(message);
            }
            var title=stringUtils.sourceNotice(ms.source)+'消息';
            if(ms.type=='NOTICE'){
                if(ms.source=='SNAP'){
                    if(ms.matter=='continuity-pay-save'){
                        this.commit('setSnapRefresh',true);
                        this.commit('setNoticeMessage',{isNoticeShow:true,close:true,title:title,type:ms.source,doId:ms.doId,message:ms.message})
                    }else if(ms.matter=='continuity-pay-success-message'){
                        this.commit('setSnapRefresh',true);
                        this.commit('setNoticeMessage',{isNoticeShow:true,close:false,title:title,type:ms.source,doId:ms.doId,message:ms.message}) 
                    }  
                }else if(ms.source=='ORDER'){
                    this.commit('setNoticeMessage',{isNoticeShow:true,close:false,title:title,type:ms.source,doId:ms.doId,message:ms.message})
                }else if(ms.source=='REDPACKET'){
                    this.commit('setNoticeMessage',{isNoticeShow:true,close:true,title:title,type:ms.source,doId:ms.doId,message:ms.message})
                }else if(ms.source=='BOND'){
                    this.commit("setBond");
                    this.commit('setNoticeMessage',{isNoticeShow:true,close:true,title:title,type:ms.source,doId:ms.doId,message:ms.message})
                }  
            }else if(ms.type=='NEWS'){
                Notify({ type: 'warning', message:ms.title });
                if(ms.source=='SNAP'){
                    this.commit('setSnapRefresh',true);
                }
            }
            //console.log('bid start:' + ms);
        },
        notice_onError(state) {
            state.serverMessage = '错误'
            state.isConnect = false;
            state.handle=false;
            client.reconnect();
        },
        notice_onClose(state) {
            state.serverMessage = '已关闭';
            state.isConnect = false;
            state.handle=false;
            client.reconnect();
        },
        notice_send(state, info) {
            state.socket.send(JSON.stringify(info));
        },
        notice_setSocket(state, handle) {
            state.socket = handle;
        },
        notice_create(state) {
            console.log('notice:'+state.memberId);
            if (state.memberId != '') {
                client.setStore();
                client.create(state.memberId);
            }

        },
        notice_close(state) {
            console.log('close');
            client.close();
            if (state.heartNoticeInterval != null) {
                clearInterval(state.heartInterval);
            }
        },
        notice_reconnect(state) {
            if (state.heartNoticeInterval != null) {
                clearInterval(state.heartInterval);
            }
            setTimeout(() => {
                if (!state.isConnect) {
                    if (state.memberId != '') {
                        client.setStore();
                        client.create(state.auctionId, state.memberId);
                    }
                }
            }, config.notice_socket.reconnectTime);
        },
    },
    actions: {
        NoticeInit(context) {
            context.commit('notice_create');
        }
    }
}

export default notice