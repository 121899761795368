import client from '@/utils/SocketClient.js'
import stringUtils from '@/utils/stringUtils.js'
import config from '@/config/configUtils.js'
import cookies from 'js-cookie';
import auctionApi from "@/api/auctionApi";
import goodsApi from "@/api/goodsApi";
import fundApi from "@/api/fundApi";
import goodsInfoApi from "@/api/goodsInfoApi"
import shopApi from "@/api/shopApi"

const socket = {
    state: {
        socket: null,
        printInfo: '',
        serverMessage: '',
        isConnect: false,
        auctionId: '',
        nowBidAmount: 0,
        item: {
            id: "",
            title: "",
            describe: "<p></p>",
            goods: [],
            startTime: new Date(),
            endTime: new Date(),
            isBond:0,
            bondRule:{
                amount:0
              },
        },
        downOptions: {
            currentTime: new Date(),
            startTime: new Date(),
            endTime: new Date(),
            delayedTime:0,
            delayedEnd:true,
            endMessage:'距离结束'
        },
        goodsInfos: [],
        roomCount:0,
        isBond:false,
        imgs: [],
        aimages:[],
        imgsDefault: [],
        redPacketList:[],
        myRedPacket:[],
        bidCount:0,
        bids: [],
        goods: {},
        shop:{
            id:null,
            goods:[]
        },
        isShop:0,
        isHandle: false,
        heartInterval: null,
        memberId: cookies.get("member-info") ? JSON.parse(cookies.get("member-info")).member.id : '',
        isMerchantId:0
    },
    mutations: {
        onOpen(state, message) {
            state.serverMessage = message;
            state.isConnect = true;
            if (state.heartInterval != null) {
                clearInterval(state.heartInterval);
            }
            state.heartInterval = setInterval(() => {
                var sendParams = { type: 'ping', message: '11111', auctionId: state.actionId, memberId: state.memberId }
                state.socket.send(JSON.stringify(sendParams));
            }, config.auction_socket.monitorTime)
        },
        onMessage(state, message) {
            var ms = { type: 'ping', message: '', result: '' };
            if (message && message != '') {
                ms = JSON.parse(message);
                console.log(message);
            }
            if (ms.type == 'BID') {
                console.log('bid start:' + ms.result);
                if (ms.result == 'accept') {
                    state.printInfo = '已接受,正在处理中.......';
                    this.commit('setNoticeMessage', { isNoticeShow: true, close: false, title: "处理消息", type: ms.source, message: state.printInfo })
                } else if (ms.result == 'success') {
                    state.printInfo = '竞价成功';
                    this.commit('setBids');
                    this.commit('setNoticeMessage', { isNoticeShow: true, close: true, title: "处理消息", type: ms.source, message: state.printInfo })
                } else {
                    state.printInfo = '竞价失败';
                    this.commit('setNoticeMessage', { isNoticeShow: true, close: true, title: "处理消息", type: ms.source, message: state.printInfo })
                }
                if(state.item.redPacket!=null&&state.item.redPacket.isAvailable==1&&state.item.redPacket.amountType==1){
                    console.log("===============redPacket===================")
                    this.commit('setRedPacket');
                    this.commit('setMyRedPacket');
                }
                //Toast(state.printInfo);
            }else if(ms.type=='DELYED'){
                console.log('DELYED');
                this.commit('setAuction');
                //state.downOptions.delayedEnd=false;
                //var nowTime=new Date();
                // state.downOptions.currentTime = nowTime.getTime();
                // state.downOptions.endTime=stringUtils.safariDate(ms.realEndTime).getTime();
            }else if (ms.type == 'BIDREFRESH') {
                this.commit('setBids');
                if(state.item.redPacket!=null&&state.item.redPacket.isAvailable==1&&state.item.redPacket.amountType==1){
                    console.log("===============redPacket===================")
                    this.commit('setRedPacket');
                    this.commit('setMyRedPacket');
                }   
            } else if (ms.type == "NEXT") {
                this.commit('setGoods', state.item.id);
                console.log('next goods')
            } else if (ms.type == "GOODSBIDEND") {
                console.log('auction end')
                state.isHandle=false;
                state.downOptions.delayedEnd=false;
                if (state.item.type == 'FIXED') {
                    this.commit('setNoticeMessage', { isNoticeShow: true, close: true, title: "处理完成", type: ms.source, message: '已被抢购' })
                } else {
                    this.commit('setNoticeMessage', { isNoticeShow: true, close: true, title: "处理完成", type: ms.source, message: '竞价已结束' })
                }

                this.commit('setAuction');
            } else if(ms.type == "HEART"||ms.type=="CONNECT"){
                this.commit('setRoomCount',ms.perCount);
            }

        },
        onError(state) {
            state.serverMessage = '错误'
            client.reconnect();
        },
        onClose(state) {
            state.serverMessage = '已关闭';
            client.reconnect();
        },
        send(state, info) {
            state.socket.send(JSON.stringify(info));
        },
        setSocket(state, handle) {
            state.socket = handle;
        },
        setBidCount(state){
            console.log(state.auctionId);
            auctionApi.getBidCount({auctionId:state.auctionId,goodsId: state.goods.id}).then(res=>{
                //state.bidCount=0;
                if(res.status==200){
                    state.bidCount=res.result;
                }
            })
        },
        setRoomCount(state,num){
            state.roomCount=num;
        },
        create(state) {
            state.isConnect = false;
            state.actionId = "";
            state.actions = {};
            state.goods = {};
            state.bids = [];
            state.handle = false;
            state.nowAmount = 0;
            state.nowBidAmount = 0;
            state.downOptions = {
                currentTime: new Date(),
                startTime: new Date(),
                endTime: new Date(),
            };
            console.log('cookies:'+cookies.get("member-info"));
            if (state.memberId != '') {
                client.setStore();
                client.create(state.auctionId, state.memberId);
            }
            state.isHandle=false;

        },
        close(state) {
            console.log('close');
            client.close();
            if (state.heartInterval != null) {
                clearInterval(state.heartInterval);
            }
        },
        reconnect(state) {
            if (state.heartInterval != null) {
                clearInterval(state.heartInterval);
            }
            setTimeout(() => {
                if (!state.isConnect) {
                    if (state.memberId != '') {
                        client.setStore();
                        client.create(state.auctionId, state.memberId);
                    }
                }
            }, config.auction_socket.reconnectTime);
        },
        setAuctionId(state, auctionId) {
            state.auctionId = auctionId;
            this.commit('setAuction');
        },
        setGoods(state, auctionId) {
            var that = this;
            var params = { auctionId: auctionId };
            console.log(auctionId)
            auctionApi.getNowBidGoods(params).then(res => {
                if (res.status == 200) {
                    console.log("---------")
                    state.goods = res.result;
                    state.nowAmount = res.result.startPrice;
                    state.nowBidAmount = res.result.startPrice;
                    if (state.memberId != '') {
                        that.commit('setBids');
                        that.commit('setBidCount');
                    }
                    that.commit('setRotation');
                    that.commit('setGoodsInfo');
                }else{
                    state.goods=state.item.goods[0];
                    that.commit('setGoodsInfo');
                    that.commit('setRotation');
                }
                that.commit('setShop');
            })

        },
        setShop(state){
            var that = this;
            if(state.goods.shopId!=null){
                shopApi.get({shopId:state.goods.shopId}).then(res=>{
                    if(res.status==200){
                        state.shop=res.result;
                        state.shop.goods=[];
                        that.commit('setShopRecommend');
                        state.isShop=1
                    }else{
                        state.isShop=0;
                    }
                })
              }
        },
        setShopRecommend(state){
            auctionApi.getShopAuction({shopId:state.goods.shopId}).then(res=>{
                if(res.status==200){
                    state.shop.goods=res.result;
                }
            })
        },
        setHandle(state, handle) {
            state.isHandle = handle;
            console.log('handle:' + handle);
            // if (handle) {
            //     this.commit('setAuction');
            // }
        },
        setDelayedEndTime(state){
            state.downOptions.endTime= state.downOptions.endTime+(state.item.delayedRule.delayTime*1000);
            state.downOptions.endMessage='延时';   
        },
        setGoodsInfo(state) {
            goodsInfoApi.getInfoList({ productId: state.goods.goodsId }).then(res => {
                if (res.status == 200) {
                    state.goodsInfos = res.result;
                }
            })
        },
        setRedPacket(state){
            auctionApi.getParticipateInNumber({auctionId:state.auctionId,goodsId:state.item.goods[0].id}).then(res=>{
                if (res.status == 200) {
                    state.redPacketList = res.result;
                }
            })
        },
        setMyRedPacket(state){
            auctionApi.getMyInNumber({auctionId:state.auctionId,goodsId:state.item.goods[0].id}).then(res=>{
                if (res.status == 200) {
                    state.myRedPacket = res.result;
                }
            })
        },
        setBids(state) {
            var that=this;
            auctionApi.bidList({ goodsId: state.goods.id }).then(res => {
                if (res.status == 200) {
                    state.bids = res.result;
                    if (state.bids.length > 0) {
                        state.nowBidAmount = state.bids[0].amount;
                    }
                    that.commit('setBidCount');
                }
            })
           
        },
        setBond(state){
            console.log('是否支付保证金：'+state.item.isBond);
            if(state.item.isBond==1){
                fundApi.isBond({auctionId:state.item.id}).then(res=>{
                    console.log('是否支付保证金：'+JSON.stringify(res));
                    if(res.status == 200){
                        state.isBond=false;
                    }else{
                        state.isBond=true; 
                    }
                })
            }
        },
        setRotation(state) {
            state.imgs = [];
            state.imgsDefault = [];
            state.aimages=[];
            goodsApi.getPicture({ goodsId: state.goods.goodsId }).then(res => {
                if (res.status == 200) {
                    res.result.forEach((a, b) => {
                        console.log(res.result[b].type);
                        if (res.result[b].type == 'rotation') {
                            state.imgs.push(res.result[b]);
                            state.aimages.push(res.result[b].pictureMiddle);
                        } else if (res.result[b].type == 'default') {
                            state.imgsDefault.push(res.result[b])
                        }
                    });
                }
            });
        },
        setAuction(state) {
            var that = this;
            var params = { auctionId: state.auctionId};
            console.log('auctionId:' + state.auctionId);
            auctionApi.get(params).then((res) => {
                if (res.status == 200) {
                    state.redPacketList=[];
                    state.isBond=false;
                    state.bidCount=0;
                    state.nowAmount=0;
                    state.nowBidAmount=0;
                    state.item = res.result;
                    if (state.item.status == 6 || state.item.bidStatus == 0) {
                        state.goods = state.item.goods[0];
                        state.nowAmount = state.goods.startPrice;
                        state.nowBidAmount = state.goods.startPrice;
                        if (state.item.bidStatus == 1) {
                            if (state.memberId != '') {
                                that.commit('setBids');
                                that.commit('setBidCount');
                            }
                        }
                        that.commit('setRotation');
                        that.commit('setGoodsInfo');
                    }
                    else {
                        that.commit('setGoods', state.item.id);
                    }
                    that.commit('setShop');
                    if(state.item.redPacket!=null&&state.item.redPacket.isAvailable==1&&state.item.redPacket.amountType==1){
                        console.log("===============redPacket init isA:"+state.item.redPacket.isAvailable+"===== isT:"+state.item.redPacket.amountType+"==============")
                        console.log("===============redPacket init ==============")
                        if (state.memberId != '') {
                            this.commit('setRedPacket');
                            this.commit('setMyRedPacket');
                        }
                    }
                    // //that.commit('setShop');
                    console.log("===============Bond isBond =============="+state.item.isBond)
                        if(state.item.isBond==1){
                            console.log("===============Bond init ==============")
                            state.isBond=true;
                            if (state.memberId != '') {
                                that.commit('setBond');
                            }
                        }
                    console.log(state.item.startTime);
                    state.isMerchantId=state.item.merchantId==state.memberId?1:0;
                    console.log("isMerchantId"+state.isMerchantId);
                    var nowTime = new Date();
                    if (state.item.status == 6) {
                        state.isHandle=false;
                        state.downOptions.currentTime = nowTime.getTime();
                        state.downOptions.startTime = nowTime.getTime();
                        state.downOptions.endTime = nowTime.getTime();
                        state.downOptions.delayedTime=0;
                        state.downOptions.delayedEnd=false;
                    } else {
                        state.downOptions.currentTime = nowTime.getTime();
                        state.downOptions.startTime = stringUtils.safariDate(state.item.startTime).getTime();
                        if(state.item.realEndTime==null){
                            state.downOptions.endTime = stringUtils.safariDate(state.item.endTime).getTime();
                        }else{
                            state.downOptions.endTime = stringUtils.safariDate(state.item.realEndTime).getTime(); 
                        }  
                    }
                    if (
                        state.downOptions.startTime < state.downOptions.currentTime &&
                        state.downOptions.endTime > state.downOptions.currentTime
                    ) {
                        state.downOptions.startTime = state.downOptions.currentTime;
                    }
                    console.log(
                        "cTime:" +
                        state.downOptions.currentTime +
                        " sTime:" +
                        state.downOptions.startTime +
                        " eTime:" +
                        state.downOptions.endTime +
                        " dTime:"+
                        state.downOptions.delayedTime*1000
                    );
                }else if(res.status==404){
                    window.location = config.indexUrl;
                }
            });
        }

    },
    actions: {
        Init(context, actionId) {
            context.commit('setAuctionId', actionId);
            context.commit('create');
        }
    }
}
export default socket