<template>
  <router-view :key="$route.fullPath" />
  <van-overlay :show="options.noticeMessage.isNoticeShow">
    <div class="wrapper" @click.stop>
      <div class="content">
        <h2 v-if="options.noticeMessage.title != ''">
          {{ options.noticeMessage.title }}
        </h2>
        <div class="notice_body">
          <div class="loading" v-if="!options.noticeMessage.close">
            <van-loading color="#0094ff" />
          </div>
          <div
            style="padding-top: 0.3rem"
            v-html="options.noticeMessage.message"
          ></div>
        </div>
        <div
          class="notice_close"
          v-if="
            options.noticeMessage.close &&
            (options.noticeMessage.type == 'REDPACKET' ||
              options.noticeMessage.type == 'FUND' ||
              options.noticeMessage.type == 'SNAP' ||
              options.noticeMessage.type == 'ORDER')
          "
        >
          <span
            style="width: 50%; color: #666; height: 3rem; line-height: 3rem"
            @click="closeNotice"
            >关闭</span
          >
          <span
            v-if="
              options.noticeMessage.type == 'SNAP' &&
              options.noticeMessage.doId != null &&
              options.noticeMessage.doId != ''
            "
            style="
              width: 50%;
              height: 3rem;
              line-height: 3rem;
              border-left: 1px solid #ebedf0;
            "
            @click="toDo"
            >查看</span
          >
          <span
            v-else-if="
              options.noticeMessage.type == 'FUND' &&
              options.noticeMessage.doId != null &&
              options.noticeMessage.doId != ''
            "
            style="
              width: 50%;
              height: 3rem;
              line-height: 3rem;
              border-left: 1px solid #ebedf0;
            "
            @click="toDo"
            >充值</span
          >
          <span
            v-else-if="
              options.noticeMessage.doId != null &&
              options.noticeMessage.doId != ''
            "
            style="
              width: 50%;
              height: 3rem;
              line-height: 3rem;
              border-left: 1px solid #ebedf0;
            "
            @click="toDo"
            >查看</span
          >
        </div>
        <div class="notice_close" @click="closeNotice" v-else>
          <span>关闭</span>
        </div>
      </div>
    </div>
  </van-overlay>
  <van-overlay
    style="z-index: 200000"
    :show="
      options.cmsFinish.ad == 0 ||
      options.commonFinish.goodTypes == 0 ||
      options.commonFinish.snap == 0
    "
  >
    <van-loading type="spinner"> 加载中.. </van-loading>
  </van-overlay>
</template>
<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import stringUtils from "@/utils/stringUtils";
export default {
  name: "home",
  setup() {
    const options = reactive({
      token: "",
      noticeMessage: {
        isNoticeShow: false,
        title: "",
        close: false,
        type: "",
        doId: "",
        message: "<div></div>",
      },
      cmsFinish: {
        ad: 0,
        message: 0,
        categorys: 0,
      },
      commonFinish: {
        goodTypes: 0,
      },
      visibilitychange: false,
    });

    const store = useStore();
    const router = useRouter();
    options.noticeMessage = computed(() => store.state.common.noticeMessage);
    options.cmsFinish = computed(() => store.state.cms.finish);
    options.commonFinish = computed(() => store.state.common.finish);
    options.visibilitychange = computed(
      () => store.state.common.visibilitychange
    );
    store.dispatch("CommonInit");
    store.dispatch("NoticeInit");
    store.dispatch("CmsInit");
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.hidden) {
          store.commit("setVisibilityChange", false);
        } else {
          store.commit("setVisibilityChange", true);
        }
      },
      false
    );
    const closeNotice = () => {
      options.noticeMessage.isNoticeShow = false;
      store.commit("setNoticeMessage", options.noticeMessage);
    };
    const toDo = () => {
      options.noticeMessage.isNoticeShow = false;
      if (options.noticeMessage.type == "SNAP") {
        router.push({ path: "/snapPayList" });
      } else if (options.noticeMessage.type == "ORDER") {
        router.push({ path: "/orderDetail/" + options.noticeMessage.doId });
      } else if (options.noticeMessage.type == "BOND") {
        router.push({ path: "/myBond" });
      } else if (options.noticeMessage.type == "FUND") {
        stringUtils.redirectForm(
          "https://api.lecuiyipai.com/fund/auth/auth",
          [],
          "post"
        );
      } else if (options.noticeMessage.type == "REDPACKET") {
        router.push({ path: "/myredpacket/" + options.noticeMessage.doId });
      }
    };
    return {
      options,
      closeNotice,
      toDo,
    };
  },
};
</script>
<style>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  width: 80%;
  height: 26vh;
  background-color: #fff;
  border-radius: 1rem;
}
.content h2 {
  padding-top: 0.13rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
}
.content .notice_body .loading {
  float: left;
  width: 1rem;
  height: 1rem;
}
.content .notice_body {
  font-size: 14px;
  flex: 1;
  min-height: 8vh;
  max-height: 20vh;
  padding: 10px 10px;
  overflow-y: auto;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-wrap: break-word;
  -webkit-overflow-scrolling: touch;
}
.content .notice_body .notice {
  width: 100%;
  text-align: center;
}
input::placeholder{
	color:#000 !important;
}
.content .notice_close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7vh;
  user-select: none;
  border-top: 1px solid #ebedf0;
}
.content .notice_close span {
  text-align: center;
  line-height: 1;
  font-size: 16px;
  cursor: pointer;
  color: #ee0a24;
}
</style>

