import axios from 'axios'
import cookies from 'js-cookie';
import config from '@/config/configUtils'
import stringUtils from "@/utils/stringUtils";

const instance = axios.create({
    timeout: 60000
});
//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(function (response) {
    console.log(response.data.status);
    if (response.data.status == 104) {
        cookies.remove('member-info', { path: '/' ,domain: "lecuiyipai.com"});
        cookies.remove('member-token', { path: '/' ,domain: "lecuiyipai.com"});
        cookies.remove('member-id', { path: '/' ,domain: "lecuiyipai.com"});
        let ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            console.log("微信环境中");
            var nowUrl = window.location.href;
            window.location = config.wxApiUrl + '?source=WX&url=' + nowUrl;
        } else {
            console.log("其他环境中（例如浏览器等等）");
            window.location = config.loginUrl;
        }
    }else if(response.data.status == 401){
        stringUtils.redirectForm(config.authUrl,[],'post');
    }
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    console.log('拦截器报错');
    return Promise.reject(error);
});

export default function (baseURL, method, url, data = null) {
    method = method.toLowerCase();
    instance.defaults.baseURL = baseURL;
    const token =cookies.get('member-token');
    if (!token && token == '') {
        return;
    }
    //设置axios为form-data
    if (method == 'post') {
        token ? data.token = token : '';
        instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        instance.defaults.transformRequest = [function (data) {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
        }]
        return instance.post(url, data)
    } else if (method == 'uploadform') {
        //data type FormData
        instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        instance.defaults.transformRequest = [function (data) {
            let fromData = data;
            if (token) {
                fromData.append('token', token);
            }
            return fromData;
        }]


        return instance.post(url, data)
    } else if (method == 'upload') {
        //data type FormData
        instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        instance.defaults.transformRequest = [function (data) {
            let fromData = new FormData();
            for (let it in data) {
                fromData.append(it, data[it]);
            }
            if (token) {
                fromData.append('token', token);
            }
            return fromData;
        }]


        return instance.post(url, data)
    } else if (method == 'get') {
        instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        return instance.get(url, data)
    }
    else {
        console.error('未知的method' + method)
        return false
    }
}