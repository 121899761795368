import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath+'gc/';
// const baseurl = 'http://localhost:8022/';
const goodsInfoApi = {
    add: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/info/add', params)
    },
    update: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/info/update', params)
    },
    get: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/info/get', params)
    },
    getInfoList:(params)=>{
        return ajaxHandle(baseurl, 'post', 'public/getInfoList', params)
    },
    getSetContext: (params) => {
        return ajaxHandle(baseurl, 'post', 'api/shop/info/getSetContext', params)
    },
    getInfoSearch: (params) => {
        return ajaxHandle(baseurl, 'post', 'public/getInfoSearch', params)
    },
}

export default goodsInfoApi