import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath+'shopcustomer';
const shopApi = {
    search: (params) => {
        return ajaxHandle(baseurl, 'post', 'public/search', params)
    },
    get:(params)=>{
        return ajaxHandle(baseurl, 'post', 'public/get', params) 
    },
    getMy:()=>{
        return ajaxHandle(baseurl, 'post', 'api/getMyShop', {}) 
    },
    recommend:(params)=>{
        return ajaxHandle(baseurl, 'post', 'public/recommend', params) 
    },
    submit:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/submit', params) 
    },
    recommendAdd:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/recommend/add', params)
    },
    recommendDel:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/recommend/delete', params)
    },
    shopHis:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/search', params)
    },
    modifyPhone:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/modifyPhone', params)
    },
    apply:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/apply', params)
    },
    collectionAdd:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/collection/add', params)
    },
    collectionDel:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/collection/delete', params)
    },
    collectionList:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/collection/my', params)
    },

}

export default shopApi