const configUtils={
    basepath:'http://api.lecuiyipai.com/',
    page:{
        size:10  
    },
    auction_socket:{
        serverUrl:'wss://api.lecuiyipai.com/asocket/bid/server',
        monitorTime:10000,
        reconnectTime:10000
    },
    logoBaseUrl:'http://img.lecuiyipai.com',
    notice_socket:{
        serverUrl:'wss://api.lecuiyipai.com/nsocket/notice/server',
        monitorTime:10000,
        reconnectTime:10000
    },
    live:{
        pushUrl:'artc://push.lecuiyipai.com/auction/',
        liveUrl:'rtmp://live.lecuiyipai.com/auction/',
        offerUrl:'https://push.lecuiyipai.com/auction/'
    },
    testpath:'http://localhost:3000/',
    loginUrl:'/auth.html',
    wxLoginUrl:'http://api.lecuiyipai.com/customer/member/channel/login?source=WX&url=http://h5.lecuiyipai.com',
    wxApiUrl:'http://api.lecuiyipai.com/customer/member/channel/login',
    authUrl:'http://api.lecuiyipai.com/fund/auth/auth',
    indexUrl:'/'

}
export default configUtils