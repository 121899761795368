import moment from "moment";
import $ from 'jquery';
import cookies from 'js-cookie';

const stringUtils = {
    dateFormat: (date, format) => {
        return moment(date).format(format)
    },
    getRedPacketAmount: (redio, nowAmount, count, type) => {
        var r = 0.00;
        console.log('redio:' + redio + 'nowAmount:' + nowAmount + 'count:' + count + 'type:' + type);
        if (count != 0) {
            if (type == 'FIXED') {
                r = redio*0.01* nowAmount;
            } else {
                r = redio *0.01 * nowAmount / count;
            }
            console.log(r);
            return parseFloat(r).toFixed(2);
        } else {
            r = redio * nowAmount*0.01/count;
            console.log('out r:'+r);
            return parseFloat(r).toFixed(2);
        }

    },
    fixedFormat: (val) => {
        return parseFloat(val).toFixed(2);
    },
    statusFormat: (status) => {
        var str = "待提交";
        switch (parseInt(status)) {
            case 0:
                str = '待提交';
                break;
            case 1:
                str = '待审核';
                break;
            case 2:
                str = '审核通过';
                break;
            case 3:
                str = '审核失败';
                break;
        }

        return str;
    },
    pictureGoodsFormat: (type) => {
        var str = "图片";
        switch (type) {
            case 'list':
                str = '首图';
                break;
            case 'rotation':
                str = '图片';
                break;
            case 'default':
                str = '默认';
                break;
        }

        return str;
    },
    sourceNotice: (source) => {
        var str = "通知";
        switch (source) {
            case 'NOTICE':
                str = '通知';
                break;
            case 'ORDER':
                str = '订单';
                break;
            case 'CAPITAL':
                str = '资金';
                break;
            case 'POINTS':
                str = '积分';
                break;
            case 'AUTH':
                str = '认证';
                break;
            case 'SNAP':
                str = '连续拍';
                break;
        }

        return str;
    },
    auctionStatusFormat: (status) => {
        var str = "新建";
        switch (parseInt(status)) {
            case 0:
                str = '新建';
                break;
            case 1:
                str = '提交审核';
                break;
            case 2:
                str = '审核通过';
                break;
            case 3:
                str = '审核未通过';
                break;
        }

        return str;
    },
    auctionTypeFormat: (input) => {
        var str = "竞拍";
        switch (input) {
            case 'DEFAULT':
                str = '竞拍';
                break;
            case 'SPECIAL':
                str = '专场拍卖';
                break;
            case 'DIRECTSEEDING':
                str = '直播拍卖';
                break;
            case 'FIXED':
                str = '一口价';
                break;
        }

        return str;
    },
    unitFormat: (input) => {
        var tab = '件';
        switch (parseInt(input)) {
            case 0:
                tab = '幅';
                break;
            case 1:
                tab = '盒';
                break;
            case 2:
                tab = '箱';
                break;
            case 3:
                tab = '个';
                break;
            case 4:
                tab = '支';
                break;
            case 5:
                tab = '双';
                break;
            case 6:
                tab = '瓶';
                break;
            case 7:
                tab = '件';
                break;
        }
        return tab;
    },
    redirectForm: (action, data, method) => {
        if (action && data) {
            var url = action;
            var inputs = '';
            const token = cookies.get('member-token');
            data.push({ "name": "token", "value": token })
            $.each(data, function (i, val) {
                inputs += '<input type="hidden" name="' + val.name + '" value="' + val.value + '" />';
            });
            $('<form action="' + url + '" method="' + (method || 'post') + '" target="_self" >' + inputs + '</form>')
                .appendTo('body').submit().remove();
        }
        return
    },
    getStatus: (status) => {
        var str = '';
        if (status == 0) {
            str = '进行中'
        } else {
            str = '结束';
        }
        return str;
    },
    safariDate: (date) => {
        var time = moment(date).format("YYYY-MM-DD HH:mm:ss");
        var sT = time.replace(/-/g, "/");
        return new Date(sT);
    }
}

export default stringUtils
