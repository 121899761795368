import config from '@/config/configUtils.js'
import { useStore } from 'vuex'
const client={
    isConnect:false,
    store:null,
    socket:null,
    isClose:false,
    setStore:()=>{
        client.store=useStore()
    },
    create:(auctionId,memberId)=>{
        
        if(typeof(WebSocket) == "undefined") {
            console.log("您的浏览器不支持WebSocket");
        }else{
            console.log("您的浏览器支持WebSocket");
            //实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
            //等同于socket = new WebSocket("ws://localhost:8888/xxxx/im/25");
            //var socketUrl=config.serverUrl;
            //socketUrl=socketUrl.replace("https","ws").replace("http","ws");
            console.log(config.auction_socket.serverUrl);
            if(client.socket!=null){
                client.socket.close();
                client.socket=null;
            }
            try {
                client.socket = new WebSocket(config.auction_socket.serverUrl+"/"+auctionId+"/"+memberId);
                client.store.commit('setSocket',client.socket);
                client.socket.onopen = function() {
                    console.log("websocket已打开");
                    //socket.send("这是来自客户端的消息" + location.href + new Date());
                    client.isConnect=true;
                    client.isClose=false;
                    client.store.commit('onOpen',"websocket已打开")
                };
                //获得消息事件
                client.socket.onmessage = function(msg) {
                    console.log(msg.data);
                    //发现消息进入    开始处理前端触发逻辑
                    client.store.commit('onMessage',msg.data);
                };
                //关闭事件
                client.socket.onclose = function() {
                    console.log("websocket已关闭");
                    client.isConnect=false;
                    client.store.commit('onClose',"websocket已关闭");
                };
                //发生了错误事件
                client.socket.onerror = function() {
                    console.log("websocket发生了错误");
                    client.isConnect=false;
                    client.store.commit('onError',"websocket发生了错误");
                }
             }
             catch(err){
                 console.log(err);
                 client.store.commit('onError',"websocket发生了错误");
             }
            
        }
    },
    close:()=>{
        if(client.socket!=null){
            client.socket.close();
            client.socket=null;
            client.isClose=true;
        }
    },
    reconnect:()=>{
        if(!client.isClose){
            console.log('reconnect');
            client.store.commit('reconnect');
        }
       
    }

}

export default client