const page={
    state:()=>({
        routername:'首页'
    }),
    getters:{
        getRouterName:(state)=>{
            return state.routername;
        },
    },
    mutations:{
        pagename(state,name){
            state.routername=name;
        }   
    },
}
export default page