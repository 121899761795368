import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath;
const orderApi = {
    search: (params) => {
        return ajaxHandle(baseurl, 'post', '/oc/api/search', params)
    },
    searchMerchant: (params) => {
        return ajaxHandle(baseurl, 'post', '/oc/api/merchant/search', params)
    },
    get:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/get', params) 
    },
    setAddress:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/setAddress', params) 
    },
    getMyCount:()=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/myCount', {}) 
    },
    myMerchantCount:()=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/merchant/count', {}) 
    },
    confirm:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/merchant/confirm', params) 
    },
    memberConfim:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api//member/confirm', params) 
    },
    deliver:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/merchant/deliver', params) 
    },
    getDeliverList:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/deliver/getDeliverList', params) 
    },
    getAllCompany:()=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/deliver/getAllCompany', {}) 
    },
    memberRefundSearch:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/member/search', params) 
    },
    memberRefundSubmit:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/member/submit', params) 
    },
    merchantRefundSearch:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/merchant/search', params) 
    },
    merchantRefundHandle:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/merchant/handle', params) 
    },
    merchantRefundFinish:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/merchant/finish', params) 
    },
    getRefund:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/get', params) 
    },
    getMyRefundCount:()=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/member/count', {}) 
    },
    myMerchantRefundCount:()=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/merchant/count', {}) 
    },
    myMerchantRefundReceipt:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/merchant/receipt', params) 
    },
    refundDeliver:(params)=>{
        return ajaxHandle(baseurl, 'post', '/oc/api/refund/member/deliver', params);
    },

}

export default orderApi