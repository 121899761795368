import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath+'cc/';
const cmsApi = {
    adAll: () => {
        return ajaxHandle(baseurl, 'post', 'advert/getAll', {})
    },
    adGet:(params)=>{
        return ajaxHandle(baseurl, 'post', 'advert/get', params) 
    },
    indexGet:(params)=>{
        return ajaxHandle(baseurl, 'post', 'index/category/get', params) 
    },
    indexAll: () => {
        return ajaxHandle(baseurl, 'post', 'index/category/getAll', {})
    },
    messageSearch:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/member/notice/my', params)
    },
    messageCount:()=>{
        return ajaxHandle(baseurl, 'post', 'api/member/notice/count', {})
    },
    messageRead:(params)=>{
        return ajaxHandle(baseurl, 'post', 'api/member/notice/read', params)
    },
    systemNotice:()=>{
        return ajaxHandle(baseurl, 'post', '/notice/list', {})
    },
    getSystemNotice:(params)=>{
        return ajaxHandle(baseurl, 'post', '/notice/get', params)
    },
    signSave:()=>{
        return ajaxHandle(baseurl, 'post', 'api/member/sign/save', {})
    },
    signList:()=>{
        return ajaxHandle(baseurl, 'post', 'api/member/sign/list', {})
    },
    signCount:()=>{
        return ajaxHandle(baseurl, 'post', 'api/member/sign/count', {})
    },

}

export default cmsApi