import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

const baseurl = config.basepath+'fund/';
const fundApi = {
    pay: (params) => {
        return ajaxHandle(baseurl, 'post', 'cumtomer/pay', params)
    },
    fastConfirm: (params) => {
        return ajaxHandle(baseurl, 'post', 'cumtomer/fastpay/confirm', params)
    },
    fastSmscode: (params) => {
        return ajaxHandle(baseurl, 'post', 'cumtomer/fastpay/smscode', params)
    },  
    getCards:() => {
        return ajaxHandle(baseurl, 'post', 'customer/fast/list', {})
    },
    createCards:(params) => {
        return ajaxHandle(baseurl, 'post', 'customer/fast/create', params)
    },
    uitsCards:(params) => {
        return ajaxHandle(baseurl, 'post', 'customer/fast/uits', params)
    },
    confirmCards:(params) => {
        return ajaxHandle(baseurl, 'post', 'customer/fast/confirm', params)
    },
    getAuth:() => {
        return ajaxHandle(baseurl, 'post', 'customer/member/get/auth', {})
    },
    getAllBank:() => {
        return ajaxHandle(baseurl, 'post', 'public/bank/all', {})
    },
    isBond:(params)=>{
        return ajaxHandle(baseurl, 'post', 'customer/bond/have', params)
    }
}

export default fundApi