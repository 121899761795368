import { createApp } from 'vue'
import App from './Home.vue'
import stringUtils from '@/utils/stringUtils'
import router from './router/index.js'
import store from './store/index.js'
import Vue3EasySwiper from 'vue3-easy-swiper'
import 'vue3-easy-swiper/dist/style.css';
import "font-awesome/css/font-awesome.min.css";
import vant from 'vant';
import 'vant/lib/index.css';
import "@babel/polyfill";
import "adapterjs"
const app=createApp(App);
app.use(store);
app.use(router);
app.use(vant);
app.use(Vue3EasySwiper);
app.config.globalProperties.stringUtils=stringUtils
app.provide('axios', app.config.globalProperties.axios);
app.mount('#app');
