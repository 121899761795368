 import config from '@/config/configUtils'
import ajaxHandle from '@/utils/ajaxHandle'

 const baseurl = config.basepath+'ac';
//const baseurl = 'http://localhost:8021';
const auctionApi = {
    search: (params) => {
        return ajaxHandle(baseurl, 'post', '/un/search', params)
    },
    get: (params) => {
        return ajaxHandle(baseurl, 'post', '/un/get', params)
    },
    getBond: (params) => {
        return ajaxHandle(baseurl, 'post', '/un/getBond', params)
    },
    publishNew: () => {
        return ajaxHandle(baseurl, 'post', '/un/publish/new', {})
    },
    startSoon: () => {
        return ajaxHandle(baseurl, 'post', '/un/start/soon', {})
    },
    fixed: () => {
        return ajaxHandle(baseurl, 'post', '/un/fixed', {})
    },
    default: () => {
        return ajaxHandle(baseurl, 'post', '/un/default', {})
    },
    launch:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/bid/launch', params)
    },
    bidList:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/bid/forGoodsIdBidList', params)
    },
    getParticipateInNumber:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/bid/getParticipateInNumber', params)
    },
    getMyInNumber:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/bid/getMyInNumber', params)
    },
    getNowBidGoods:(params) => {
        return ajaxHandle(baseurl, 'post', '/un/getNowBidGoods', params)
    },
    searchMy:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/search', params)
    },
    onekey:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/onekey', params)
    },
    sGet:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/get', params)
    },
    add:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/add', params)
    },
    addGoods:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/goods/add', params)
    },
    update:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/update', params)
    },
    updateGoods:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/goods/update', params)
    },
    delete:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/delete', params)
    },
    deleteGoods:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/goods/delete', params)
    },
    online:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/online', params)
    },
    copy:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/copy', params)
    },
    offline:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/offline', params)
    },
    addRedPacket:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/shop/redpacket/add', params)
    },
    getBidCount:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/bid/getBidCount', params)
    },
    getAuctionCount:() => {
        return ajaxHandle(baseurl, 'post', '/api/shop/totalCount', {})
    },
    getMyBond:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/bid/getMyBond', params)
    },
    getMyRedPacket:(params) => {
        return ajaxHandle(baseurl, 'post', '/api/bid/getMyRedPacket', params)
    },
    getShopAuctionTotal:(params) => {
        return ajaxHandle(baseurl, 'post', '/un/getShopAuctionTotal', params)
    },
    getShopAuction:(params) => {
        return ajaxHandle(baseurl, 'post', '/un/getShopAuction', params)
    },
}

export default auctionApi