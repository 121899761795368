import { createStore } from 'vuex'
import page from './module/page'
import common from './module/common'
import cms from './module/cms'
import notice from './module/notice'
import socket from './module/socket'

export default createStore({
  modules:{
    common,
    notice,
    socket,
    cms,
    page
  }
})